import React from "react";
import styled from "styled-components"
import { graphql } from "gatsby";
import { ButtonLink } from "../components/ButtonLink";
import Layout from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { ArticlePhotoRowLinks } from "../components/ArticlePhotoRowLinks";
import theme, {breakpoints} from "../style/theme";

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 2rem 0;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.sm}px) {
    display: block;
    padding: 0.5rem 0;
  }
`

export default function TagTemplate({
  data: {
    site: {
      siteMetadata: {
        siteUrl,
        templates: {
          posts: {
            pathPrefix,
            filters: {
              tag: { pathPrefixTag, totalPosts },
            },
          },
        },
      },
    },
    allMdx: { edges: posts },
  },
  pageContext: { tag }
}) {
  return (
    <Layout title={`#${tag}の記事一覧`}>
      <Breadcrumbs
        links={[
          {
            url: `/${pathPrefix}/page/1`,
            title: 'BLOG',
          },
          {
            url: null,
            title: `#${tag}`,
          },
        ]}
      />
      <ArticlePhotoRowLinks
        edges={posts}
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
      />
      {posts.length === totalPosts && (
        <ButtonWrapper>
          <ButtonLink
            to={`/${pathPrefixTag}/${tag}/page/1`}
          >
            #{tag} の記事を全て見る
          </ButtonLink>
        </ButtonWrapper>
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($tag: String!, $limit: Int!) {
    site {
      siteMetadata {
        siteUrl
        templates {
          posts {
            pathPrefix
            filters {
              tag {
                pathPrefixTag
                totalPosts
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 50, truncate: true)
          fileAbsolutePath
          frontmatter {
            id
            title
            category
            description
            tags
            createdDate
            updatedDate
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
