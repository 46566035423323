import React from "react";
import styled from "styled-components";
import theme from "../style/theme";
import { Link } from "gatsby";

const Button = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  margin: 2rem 0;
  text-decoration: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: .13em;
  box-sizing: border-box;
  color: ${theme.palette.primary.main};
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.primary.main};
  transition: .5s;
  &:after {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    padding: 0;
    transition: .5s;
    content: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M5 14l7-6.5L5 1" stroke="%231b1f22" stroke-linecap="square"></path></svg>');
  }
  &:hover {
    color: ${theme.palette.white};
    background-color: ${theme.palette.primary.main};
    &:after {
      transition: .5s;
      content: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M5 14l7-6.5L5 1" stroke="%23fff" stroke-linecap="square"></path></svg>');
    }
  }
`

const isExternalUrl = url => url && url.startsWith("http");

export const ButtonLink = ({ to=null, href=null, children }) => {
  if (to) {
    return (
      isExternalUrl(to) ? (
        <Button
          href={to}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </Button>
      ) : (
        <Button
          to={to}
        >
          {children}
        </Button>
      )
    )
  } else {
    return (
      isExternalUrl(href) ? (
        <Button
          href={href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </Button>
      ) : (
        <Button
          href={href}
        >
          {children}
        </Button>
      )
    )
  }
}
